import React from "react";
import Layout from "components/Layout";
import Seo from "components/Seo";
import SubpageHeader from "../components/SubpageHeader";
import SectionHeading from "components/SectionHeading/";
import TitleWithLine from "../components/TitleWithLine";
import WhiteTile from "../components/WhiteTile";
import "styles/pages/page-analysis.scss";

const Analysis = ({ pageContext }) => {
  const analysisPage = pageContext.pageContent;
  return (
    <Layout>
      <Seo
        title={
          pageContext.seo.title ? pageContext.seo.title : pageContext.title
        }
        description={pageContext.seo?.description}
      />
      <SubpageHeader
        title={pageContext.title}
        image={require("assets/images/example-subpage-bg.jpg").default}
      />
      <section className="section-analysis">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="section-analysis__inner">
                <TitleWithLine variant="bottom">
                  {analysisPage.limitlessAnalysingFlexibilityTitle}
                </TitleWithLine>
                <div
                  dangerouslySetInnerHTML={{
                    __html: analysisPage.limitlessAnalysingFlexibilityText,
                  }}
                  className="section-analysis__text"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <img
                src={analysisPage.limitlessAnalysingFlexibilityImage?.sourceUrl}
                alt=""
                className="img-fluid section-analysis__img"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="section-advantages">
        <div className="container">
          <SectionHeading
            className="section-advantages__title"
            title={analysisPage.significantCompetetiveAdvantageTitle}
            subtitle={analysisPage.significantCompetetiveAdvantageSubtitle}
          />
          <div className="row justify-content-between">
            {analysisPage.significantCompetetiveAdvantageRow1.map(
              (tile, index) => {
                return (
                  <div className="col-xl-3 col-md-6" key={index}>
                    <WhiteTile>{tile.sectionScaRow1TileText}</WhiteTile>
                  </div>
                );
              }
            )}
          </div>
          <div className="section-advantages__row">
            {analysisPage.significantCompetetiveAdvantageRow2.map(
              (tile, index) => {
                return (
                  <div className="section-advantages__item-wrapper" key={index}>
                    <WhiteTile>{tile.sectionScaRow2TileText}</WhiteTile>
                  </div>
                );
              }
            )}
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Analysis;
